/* Accordion */

jQuery( function($) {
  $( ".c-accordion" ).accordion({
    heightStyle: "content",
    header: '.c-accordion__block > h3',
    collapsible: true,
    animate: 240
  });
});
