/*
  Project: elevenvr
  Author: i3 Digital
 */

window.jQuery = window.$ = require('jquery');
require('./jquery-ui');

/* Vendor */

require('slick-carousel');
require('jquery.mmenu');

require('./vendor/jquery-stickit');
require('./vendor/js-split');
require('./vendor/scrollspy');

/* Custom Scripts */

require('./custom/accordion');
require('./custom/slick-slider');
require('./custom/mobile-search');
require('./custom/mmenu');
require('./custom/share-btn');
require('./custom/scroll-top');
require('./custom/quick-nav');
require('./custom/filter-results');
require('./custom/language-select');
