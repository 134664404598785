// === Split headings to enable adding backgorund. Works on any instance of .js-split.

var bw = bw || {};

bw.splitHeadings = {

    statementsArray: null,
    checkNumber: null,

    init: function () {
        var timeout;
        bw.splitHeadings.checkNumber = 0;
        this.collectStatementsToSplit();

        $(window).resize(function() {
            clearTimeout(timeout);
            timeout = setTimeout(bw.splitHeadings.splitAllHeadingsNew(), 100);
        });

        bw.splitHeadings.splitAllHeadingsNew();
    },

    collectStatementsToSplit: function () {
        this.statementsArray = [];
        $(".js-split").each(function (i, inst) {
            var tempStatement = $(inst).html();
            bw.splitHeadings.statementsArray.push(tempStatement);
        });
    },

    splitAllHeadingsNew: function () {


        $(".js-split").each(function (i, inst) {
            $(inst).html(bw.splitHeadings.statementsArray[i]);

            var wordToWrap;

            // detect special word to wrap
            var testString = $(inst).html();
            var div = document.createElement("div");
            div.innerHTML = testString;
            wordToWrap = $(div).find('em').text();
            // ===

            // Clean <em> tag from string
            var cleanText = testString.replace(/<\/?[^>]+(>|$)/g, "");
            $(inst).html(cleanText);

            var spanInserted = $(inst).html().split(" ").join(" </span><span>");
            var wrapped = ("<span>").concat(spanInserted, "</span>");
            $(inst).html(wrapped);

            var refPos = $(inst).find('span:first-child').position().top;
            var newPos;
            $(inst).find('span').each(function (j, instSpan) {
                newPos = $(instSpan).position().top;

                if (j == 0) {
                    return;
                }
                if (newPos == refPos) {
                    $(instSpan).prepend($(instSpan).prev().text() + " ");
                    $(instSpan).prev().remove();
                }
                refPos = newPos;

                // Final correction to renedered text
                $(inst).find('span').each(function (x, instX) {
                    // remove double spaces
                    var str = $(instX).text();
                    var strNew = str.replace(/ +(?= )/g, '');
                    $(instX).html(strNew);

                    // wrap in em tag
                    //var str = $(instX).text();
                    //var re = new RegExp(wordToWrap, "g");
                    //var strNew = str.replace(re, '<em>' + wordToWrap + '</em>');
                    //$(instX).html(strNew);
                });

                // Animate heading - plugin scroll Magic?
                // setTimeout(function() {
                //   $(inst).removeClass('js-split--anim-init');
                // }, 500);
            });

        });

    }

};

// === App Root
function bwAppGlobal() {
    bw.splitHeadings.init();
    $('.js-split').addClass('active');
};

bwAppGlobal();
