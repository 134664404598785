// FILTER RESULTS

jQuery(document).ready(function($){

  $('.c-filter--btn').click(function() {
    $(this).toggleClass('active');
    $(this).next('UL').toggleClass('active');
  });
  
});
